<template>
  <div class="flex-center-col">
    <div class="mr-5">Quantity</div>
    <v-btn
      tile
      outlined
      height="40px"
      min-width="0"
      width="40px"
      class="button-theme"
      @click="decrease"
      :disabled="value <= 1"
    >
      <v-icon small>remove</v-icon>
    </v-btn>
    <div class="mx-2" style="width: 60px; height: 40px">
      <v-text-field
        outlined
        dense
        disabled
        hide-details
        oninput="value=value.replace(/[^\d]/g,'')"
        style="border-radius: 0"
        :value="value"
        @change="inputQuantity"
      ></v-text-field>
    </div>
    <v-btn
      tile
      outlined
      height="40px"
      min-width="0"
      width="40px"
      class="button-theme"
      @click="increase"
      :disabled="quantityExceed"
    >
      <v-icon small>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'QuantitySelector',
  props: {
    value: {
      default: 1
    },
    quantityExceed: {
      default: false
    }
  },
  methods: {
    increase() {
      if (!this.value) {
        return
      }
      this.$emit('increase')
    },
    decrease() {
      if (!this.value) {
        return
      }
      if (this.value > 1) {
        this.$emit('decrease')
      }
    },
    inputQuantity(q) {
      if (q == 0 || !q) {
        this.$emit('setAtLeast')
      } else {
        this.$emit('setInput', q)
      }
    }
  }
}
</script>

<style scoped>
.v-text-field >>> input {
  text-align: center;
}
.button-theme {
  border: solid 1px var(--bkk-darkblue);
  background: var(--bkk-darkblue);
  color: #fff;
}
.button-theme.v-btn--disabled {
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.26);
}
</style>
