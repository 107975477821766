<template>
  <div style="height: 100%">
    <v-layout
      pr-2
      :style="{
        'max-height': $vuetify.breakpoint.smAndDown ? '420px' : '600px'
      }"
    >
      <v-flex xs2 mr-2>
        <div style="height: 90%; overflow: hidden auto">
          <div
            v-for="(item, i) in images"
            :key="i"
            :class="{ 'image-active': i === current }"
            class="mb-3 flex-center-row"
            @click="current = i"
          >
            <v-img
              contain
              class="image-list"
              aspect-ratio="0.8"
              height="100%"
              :src="item.image_url"
            ></v-img>
          </div>
        </div>
        <v-layout justify-space-around align-end style="height: 10%">
          <v-flex shrink>
            <v-btn
              tile
              :x-small="$vuetify.breakpoint.smAndDown"
              outlined
              height="25px"
              width="100%"
              min-width="35px"
              @click="current++"
            >
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink>
            <v-btn
              tile
              :x-small="$vuetify.breakpoint.smAndDown"
              outlined
              height="25px"
              width="100%"
              min-width="35px"
              @click="current--"
            >
              <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <v-carousel
          :interval="interval"
          :height="height"
          :show-arrows="false"
          hide-delimiters
          vertical
          hide-delimiter-background
          v-model="current"
        >
          <v-carousel-item v-for="(item, i) in images" :key="i">
<!--            <v-hover v-slot="{ hover }">-->
              <v-img
                contain
                width="100%"
                max-height="100%"
                :src="item.image_url"
                @click="showFull(item.image_url)"
              >
<!--                <zoom-on-hover-->
<!--                  v-if="hover"-->
<!--                  :img-normal="item.image_url"-->
<!--                  :scale="4"-->
<!--                ></zoom-on-hover>-->
              </v-img>
            <!--            </v-hover>-->
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ProductCarousel',
  props: {
    images: {
      default: []
    },
    interval: {
      default: 2000
    },
    height: {
      default: 600
    }
  },
  data() {
    return {
      current: 0
    }
  },
  methods: {
    showFull (image) {
      this.$emit('fullDialog', image)
    }
  }
}
</script>

<style scoped lang="scss">
.image-list {
  width: 90px;
  max-width: 100%;
  cursor: pointer;
}
.image-active {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: swing;
  animation-name: swing;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  padding: 0 0;
}
.v-btn:not(.v-btn--round) {
  padding: 0 4px;
}
</style>
